import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor() { }

  allFields(form: FormGroup): void {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

  field(form: FormGroup, validationType: string, field: string): boolean {
    if (form.get(field).hasError(validationType) &&
        (form.get(field).dirty ||
        form.get(field).touched)) {
      form.get(field).markAsDirty({ onlySelf: true });
      return true;
    }
    return false;
  }

  fieldOnly(control: FormControl, validationType: string): boolean {
    if (control.hasError(validationType) && (control.dirty || control.touched)) {
      control.markAsDirty({ onlySelf: true });
      return true;
    }
    return false;
  }

  markDirtyField(control: FormControl): void {
    control.markAsDirty({ onlySelf: true });
    control.markAsTouched({ onlySelf: true });
  }

  alphaNumberOnly(e: any): any {  // Accept only letters not numerics, not special characters
    const regex = new RegExp("^[a-zA-Z ]+$");
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);

    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e: any): any {
    e.preventDefault();
    return false;
  }
}
