import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { AuthService } from 'src/app/core/service/auth.service';
import { IUserRes } from 'src/app/interfaces/user.interface';
import { AlertService } from 'src/app/services/utilities/alert.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  showForm = false;
  signature = '';
  email = '';

  resetPassProgressBtn: MatProgressButtonOptions = {
    active: false,
    text: 'Reestablecer mi contraseña',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    customClass: 'login100-form-btn',
    mode: 'indeterminate'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.signature = this.route.snapshot.queryParams.signature;
    this.email = this.route.snapshot.params.email;


    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required]
    });

    if (this.email && this.signature) {
      setTimeout(() => {
        this.showForm = true;
      }, 500);
    } else {
      this.alertService.warningMessage('Aviso', 'Información inválida');
      this.router.navigate(['/authentication/signin']);
    }
  }

  get f() {
    return this.form.controls;
  }

  save(): void {
    if (this.form.invalid) {
      this.alertService.warningMessage('Aviso', 'Completa el formulario');
      return;
    } else {
      this.authService.resetPassword(this.form.value, this.email, this.signature)
      .subscribe({
        next: (result: IUserRes) => {
          if (result.status) {
            this.router.navigate(['/authentication/signin']);
            this.alertService.successDialog('Aviso', 'Has restablecido correctamente tu contraseña');
          } else {
            this.alertService.errorMessage('Error', result.msg);
            this.router.navigate(['/authentication/signin']);
          }
        },
        error: (err: any) => {
          this.alertService.errorMessage('Error', err);
          this.router.navigate(['/authentication/signin']);
          this.resetPassProgressBtn.active = false;
        },
        complete: () => {
          this.resetPassProgressBtn.active = false;
        }
      });
    }
  }
}
