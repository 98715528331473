import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITitle } from 'src/app/core/models/title.interface';

@Injectable({
  providedIn: 'root'
})
export class ViewTitleService {
  // USER VIEWS
  private titleSubject: BehaviorSubject<ITitle>;
  public title: Observable<ITitle>;

  constructor() {
    this.loadData();
  }

  loadData(): void {
    // TITLE DATA
    this.titleSubject = new BehaviorSubject<ITitle>(
      JSON.parse(localStorage.getItem('title'))
    );
    this.title = this.titleSubject.asObservable();
  }

  // USER DATA
  public get titleValue(): ITitle {
    return this.titleSubject.value;
  }

  public set titleValue(data: ITitle) {
    localStorage.setItem('title', JSON.stringify(data));
    this.titleSubject.next(data);
  }

}
