import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-under-costruction',
  templateUrl: './under-costruction.component.html',
  styleUrls: ['./under-costruction.component.sass']
})
export class UnderCostructionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
