import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { Page404Component } from './components/authentication/page404/page404.component';
import { AuthLayoutComponent } from './components/layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './components/layout/app-layout/main-layout/main-layout.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: '',
        loadChildren: () =>
          import('./components/sections/sections.module').then(
          (m) => m.SectionsModule
        )
      }
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./components/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  {
    path: 'reset-password/:email',
    component: ResetPasswordComponent
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
