<div class="limiter">
    <div class="container under-construction-container">
        <div class="img-container d-flex justify-content-center">
            <img class="" width="600" src="assets/images/other/under-construction.png">
        </div>
        <div class="d-flex justify-content-center header">
            <span>
               Sección en costrucción
            </span>
        </div>
    </div>
  </div>
  