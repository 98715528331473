<section class="content">
    <div class="container-fluid">
      <div *ngIf="title" class="block-header d-flex justify-content-between col-12 pt-3">
        <h4>{{title.title}} <span *ngIf="title.subtitle" class="font-15"> > {{title.subtitle}} </span></h4>
        <h5 *ngIf="dateFilter.start"><span style="font-weight: 400;">Rango de fecha:</span> {{dateFilter.start | date}} al {{dateFilter.end | date}}</h5>
      </div>
      <!--div class="block-header" *ngFor="let breadscrum of breadscrums">
        < breadcrumb >
        <app-breadcrumb [title]="breadscrum.title" [items]="breadscrum.items" [active_item]="breadscrum.active">
        </app-breadcrumb>
      </div-->
      <div class="">
        <!-- section content -->
        <router-outlet></router-outlet>
      </div>
    </div>
</section>
  