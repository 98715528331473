import { AbstractControl, ValidationErrors } from '@angular/forms';

export class WhiteSpaceValidator {
    static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
        if (control.value && !control.value.toString().trim()){
            return {noWhiteSpace: true};
        }
        return null;
    }
}
