import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './app-layout/main-layout/main-layout.component';
import { SharedModule } from '../../shared/shared.module';
import { ContentLayoutComponent } from './content-layout/content-layout.component';
import { HeaderComponent } from './header/header.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UnderCostructionComponent } from './under-costruction/under-costruction.component';

const components = [
  HeaderComponent,
  PageLoaderComponent,
  SidebarComponent,
  RightSidebarComponent,
  AuthLayoutComponent,
  MainLayoutComponent,
  ContentLayoutComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    components,
    UnderCostructionComponent
  ],
  exports: [
    components
  ]
})
export class LayoutModule {}
